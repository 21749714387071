@import '../root.css';

@media screen and (max-width:600px) {
    .section-consultor{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 110vh;
        align-items: center;
        justify-content: center;
        gap: 5vw;
        font-family: 'Barlow', sans-serif;
        margin: 10vh 0vw 20vh;
        background-color: #1D1C1C;
        color: white;
        z-index: 0;
    }
    .box-consultor-texto{
        display: flex;
        flex-direction: column;
        gap: 6vh;
        width: 90%;
    }
    .titulo-consultor{
        font-size: 3.5rem
    }
    .descricao-consultor{
        font-size: 1.3rem;
        color: white;
    }
    .linha-consultor{
        width: 20%;
        height: 1vh;
        background-color: #ffcd2a;
    }
    .button-consultor{
        width: fit-content;
        height: fit-content;
        padding: 2vh 2vw;
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 700;
        color: white;
        border: 3px solid #ffffff;
        cursor: pointer;
        position: relative;
        background-color: transparent;
        overflow: hidden;
        font-family: 'Barlow', sans-serif;
       }
       .button-consultor::before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #113892;
        transform: translateX(-100%);
        transition: all .3s;
        z-index: -1;
       }
       
    .button-consultor:hover{
        color: white;
        border: 3px solid #113892;
    }
    .button-consultor:hover::before{
        transform: translateX(0);
    }
    .icone-consultor{
        display: none;
    }
}
@media screen and (min-width:900px) {
    .section-consultor{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 110vh;
        align-items: center;
        justify-content: center;
        gap: 5vw;
        font-family: 'Barlow', sans-serif;
        margin: 10vh 0vw 20vh;
        z-index: 0;
    }
    .box-consultor-texto{
        display: flex;
        flex-direction: column;
        gap: 4vh;
        width: 40%;
    }
    .titulo-consultor{
        font-size: 3.5rem;
        color: white;
    }
    .descricao-consultor{
        font-size: 1.3rem;
        color: white;
    }
    .linha-consultor{
        width: 20%;
        height: 1vh;
        background-color: #ffcd2a;
    }
    .button-consultor{
        width: fit-content;
        height: fit-content;
        padding: 2vh 2vw;
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 700;
        color: rgb(255, 255, 255);
        border: 3px solid #ffffff;
        cursor: pointer;
        position: relative;
        background-color: transparent;
        overflow: hidden;
        font-family: 'Barlow', sans-serif;
       }
       .button-consultor::before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #1aff00;
        transform: translateX(-100%);
        transition: all .3s;
        z-index: -1;
       }
       
    .button-consultor:hover{
        color: white;
        border: 3px solid #1aff00;
    }
    .button-consultor:hover::before{
        transform: translateX(0);
    }
    .icone-consultor{
        width: 45%;
        height: 60vh;
        border-radius: 5px;
        box-shadow: #ffcd2a -20px 20px;
    }
}