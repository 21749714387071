@import '../root.css';

@media screen and (max-width:600px) {
    .section-footer{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        background-color: rgb(30, 30, 30);
        color: white;
        padding: 5vh 2vw;
    }
    .box-icone-slogan{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 2vw;
    }
    .icone-alianca-footer{
        width: 50vw;
        height: 30vw;   
    }
    .slogan-footer{
        font-family: 'Barlow', sans-serif;
        font-size: 1.2rem;
    }
    .box-contato, .box-links, .box-info{
        font-family: 'Barlow', sans-serif;
        display: flex;
        flex-direction: column;
        gap: 1vh;
    }
    .box-contato{
        width: 100%;
    }
    .box-links{
        width: 100%;
    }
    .box-info{
        flex-direction: row;
        margin-top: 2vh;
    }
    .titulo-box-contato, .titulo-box-links{
        font-size: 2.3rem;
        font-weight: 500;
    }
    .texto-info-contato, .link-footer{
        margin-top: 1vh;
        font-size: 1.3rem;
        font-weight: 300;
        text-align: left;
    }
    .icone-info-contato{
        width: 6vh;
        height: 6vh;
        background-color: #ffcd2a;
        padding: 1vh 1vh;
        border-radius: 5px;
    }
    .horario{
        padding-left: 7vh;
        position: relative;
        bottom: 2vh;
    }
    .titulo-info-horario{
        font-family: 'Barlow', sans-serif;
        font-weight: 400;
        font-size: 1.7rem;
    }
}
@media screen and (min-width:900px) {
    .section-footer{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        background-color: rgb(30, 30, 30);
        color: white;
        padding: 5vh 0vw;
        z-index: -2;
    }
    .box-icone-slogan{
        width: 30%;
    }
    .icone-alianca-footer{
        width: 25vw;
        height: 10vw;   
    }
    .slogan-footer{
        font-family: 'Barlow', sans-serif;
        font-size: 1.3rem;
        color: #b6b6b6;
    }
    .box-contato, .box-links, .box-info{
        font-family: 'Barlow', sans-serif;
        display: flex;
        flex-direction: column;
        gap: 1vh;
    }
    .box-contato{
        width: 40%;
    }
    .box-links{
        width: 20%;
    }
    .box-info{
        flex-direction: row;
        margin-top: 2vh;
    }
    .titulo-box-contato, .titulo-box-links{
        font-size: 1.8rem;
        font-weight: 500;
    }
    .texto-info-contato, .link-footer{
        margin-top: 1vh;
        font-size: 1.3rem;
        font-weight: 300;
        text-align: left;
        color: #b6b6b6;
    }
    .icone-info-contato{
        width: 6vh;
        height: 6vh;
        background-color: #ffcd2a;
        padding: 1vh 1vh;
        border-radius: 5px;
    }
    .horario{
        color: #b6b6b6;
    }
    .titulo-info-horario{
        font-family: 'Barlow', sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
    }
}