@import '../root.css';

.banner_app{
    width: 100%;
    height: 100vh;
}

.titulo_banner_app{
    font-family: 'Barlow', sans-serif;
    color: white;
    border-radius: 10px;
    font-size: 3rem;
    text-align: center;
}
.button-baixar-app{
    width: 18vw;
    height: 13vh;
    margin-top: 10vh;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    background-image: url('../../assets/botao-app.png');
    background-size: cover;
    background-position: center;
   }

@media screen and (max-width: 600px) {
    
    .titulo_banner_app{
        font-family: 'Barlow', sans-serif;
        color: white;
        border-radius: 10px;
        width: 90%;
        font-size: 1.7rem;
        text-align: center;
    }
    .banner_app{
        width: 100%;
        height: 200vh;
    }
    .button-baixar-app{
        width: 38vw;
        height: 13vh;
        margin-top: 10vh;
        background-color: transparent;
        cursor: pointer;
        position: relative;
        background-image: url('../../assets/botao-app.png');
        background-size: cover;
        background-position: center;
       }
}