@import '../root.css';

@media screen and (min-width:900px) {
    .section-sobre-nos{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 60vh;
        align-items: center;
        justify-content: center;
        gap: 5vw;
        font-family: 'Barlow', sans-serif;
        margin: 10vh 0vw;
    }
    .box-sobre-nos-texto{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4vh;
        width: 40%;
        height: 100%;
    }
    .titulo-sobre-nos{
        font-size: 3.5rem;
        color: white;
    }
    .descricao-sobre-nos{
        font-size: 1.3rem;
        font-weight: 400;
        color: white;
    }
    .linha-sobre-nos{
        width: 40%;
        height: 1vh;
        background-color: #ffcd2a;
    }
    .icone-sobre-nos{
        width: 45%;
        height: 60vh;
        border-radius: 5px;
        box-shadow: #ffcd2a 20px 20px;
    }
}
@media screen and (max-width:600px) {
    .section-sobre-nos{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 5vw;
        font-family: 'Barlow', sans-serif;
        margin: 10vh 0vw;
    }
    .box-sobre-nos-texto{
        display: flex;
        flex-direction: column;
        gap: 6vh;
        width: 90%;
    }
    .titulo-sobre-nos{
        font-size: 3.5rem;
        color: white;
    }
    .descricao-sobre-nos{
        font-size: 1.3rem;
        color: white;
    }
    .linha-sobre-nos{
        width: 20%;
        height: 1vh;
        background-color: #ffcd2a;
    }
    .icone-sobre-nos{
        display: none;
    }
}