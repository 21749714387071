@import '../root.css';

.box-header {
  height: 9vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0vh 3vw;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
}

.box-navegacao-header {
  width: 80vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 20;
}

.icone-logo-header{
  width: 10vw;
  z-index: 20;
  position: absolute;
}

.link-header {
  font-family: 'Barlow', sans-serif;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  position: relative;
  top: 5vh;
}

.link-header::after {
  content: " ";
  width: 0%;
  height: 1px;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all ease-in-out .5s;
}

.link-header:hover::after {
  width: 100%;
}

.button-area-cliente {
  width: fit-content;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5vh 2vw;
  color: black;
}

.button-area-cliente:hover {
  transform: scale(1.1);
  transition: all .5s;
}

.button-area-cliente::after {
  height: 0px;
}
.navbar-toggle{
  display: none;
}
/* Estilos para a navbar em modo responsivo */
@media screen and (max-width: 600px) {
  .box-header {
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0vh 3vw;
    align-items: center;
    justify-content: space-between;
    background-color: #1D1C1C;
  }

  .box-navegacao-header {
    width: 30vw;
    position: absolute;
    left: 65vw;
    top: 0;
    background-color: black;
    display: none; /* Alteração aqui */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 2vh 0vw;
    gap: 2vh;
  }

  .icone-logo-header {
    width: 40vw;
    height: 100%;
    display: none;
  }

  .link-header {
    font-family: 'Barlow', sans-serif;
    color: white;
    font-size: 1rem;
    margin-top: 1vh;
    position: relative;
  }
  .navbar-toggle {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
  }
  .button-area-cliente {
    color: black;
  }

  .navbar-toggle-icon {
    position: relative;
    right: 150px;
    display: block;
    width: 30px;
    height: 2px;
    margin-bottom: 4px;
    transition: transform 0.3s;
    font-size: 1rem;
    color: white
  }

  .navbar-toggle.open .navbar-toggle-icon {
    transform: rotate(45deg);
  }

  .nav-open .box-navegacao-header {
    display: flex;
  }
}
